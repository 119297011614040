/* eslint-disable @typescript-eslint/no-unused-vars */

import { RequestParams } from "../../../utils/RequestParams";





import api from "../../../utils/api";

const get = async (headers) => {
  const requestParams = new RequestParams({}, headers);
  const response = await api.get('paragraphExtraction/extractors', requestParams);
  return response.json;
};

const save = async (extractorValues) => {
  const modelPayload = {
    sourceTemplateId: extractorValues.sourceTemplateId,
    targetTemplateId: extractorValues.targetTemplateId,
    paragraphPropertyId: extractorValues.paragraphPropertyId,
    paragraphNumberPropertyId: extractorValues.paragraphNumberPropertyId,
    sourceRelationshipTypeId: extractorValues.sourceRelationshipId,
    targetRelationshipTypeId: extractorValues.targetRelationshipId
  };

  const requestParams = new RequestParams(modelPayload);
  return api.post('paragraphExtraction/extractor', requestParams);
};

const remove = async (extractors) =>
Promise.all(
  extractors.map((extractor) => {
    const id = extractor._id;
    const requestParams = new RequestParams({ id });
    return api.delete('paragraphExtraction/extractor', requestParams);
  })
);

export { get, save, remove };