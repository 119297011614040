
import qs from 'qs';
import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";


const getByParagraphExtractorId = async (
parameters,
headers) =>
{
  try {
    const requestParams = new RequestParams(qs.stringify(parameters), headers);
    const { json: response } = await api.get('paragraphExtraction/entityParagraphs', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

export { getByParagraphExtractorId };