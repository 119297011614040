/* eslint-disable @typescript-eslint/no-unused-vars */

import qs from 'qs';
import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";
import {



  EntityStatus } from "../../shared/ParagraphExtractionTypes";


const get = async (
parameters,
headers) =>
{
  try {
    const requestParams = new RequestParams(qs.stringify(parameters), headers);
    const { json: response } = await api.get(
      'paragraphExtraction/extractorStatuses',
      requestParams
    );
    return response;
  } catch (e) {
    return e;
  }
};

const extractParagraphs = async (extractorId, headers) => {
  try {
    const modeledPayload = {
      extractorId,
      status: EntityStatus.New
    };
    const requestParams = new RequestParams(modeledPayload, headers);
    const response = await api.post('paragraphExtraction/extractNew', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const extractSelected = async (
extractorId,
entityIds,
headers) =>
{
  try {
    const modeledPayload = {
      extractorId,
      entitySharedIds: entityIds.map((entity) => entity.entity.sharedId)
    };
    const requestParams = new RequestParams(modeledPayload, headers);
    const response = await api.post('paragraphExtraction/extract', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const remove = async (entries) => {
  //model values to be sent to backend, adjust this to satisfy backend requirements
  const modeledPayload = {
    ids: entries.map((entry) => entry.entity._id)
  };

  const requestParams = new RequestParams(modeledPayload);
  return Promise.resolve();
  // uncomment this once backend is ready
  // return api.delete(ENDPOINTS.DELETE_EXTRACTOR, requestParams);
};

export { get, extractParagraphs, extractSelected, remove };