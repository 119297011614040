function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}

import * as extractorsAPI from "../../../api/paragraphExtractor/extractors";
import * as pxParagraphApi from "../../../api/paragraphExtractor/paragraphs";
import * as pxEntitiesApi from "../../../api/paragraphExtractor/entities";
import * as entitiesApi from "../../../api/entities";
import * as settingsApi from "../../../api/settings";
import * as templatesApi from "../../../api/templates";







import { searchParamsFromSearchParams } from "../../../../utils/routeHelpers";


const PAGE_SIZE = 10;

const ParagraphExtractorLoader =
(headers) =>
async () => {
  const extractors = await extractorsAPI.get(headers);
  return { extractors };
};

const PXEntityLoader =
(headers) =>
// eslint-disable-next-line max-statements
async ({ request, params: { extractorId } }) => {var _pxEntityRows$rows;
  const urlSearchParams = new URLSearchParams(request.url.split('?')[1]);
  const { page = '1', status } = searchParamsFromSearchParams(urlSearchParams);

  const result = {
    rows: [],
    page: { number: page, size: PAGE_SIZE },
    totalRows: 0
  };

  if (!extractorId) return result;

  const query = _objectSpread({
    id: extractorId,
    page: { number: Number(page), size: PAGE_SIZE } },
  status ? { filter: { status: [status].flat() } } : {});


  const [extractors, pxEntityRows] = await Promise.all([
  extractorsAPI.get(headers),
  pxEntitiesApi.get(query, headers)]
  );

  (_pxEntityRows$rows = pxEntityRows.rows) === null || _pxEntityRows$rows === void 0 ? void 0 : _pxEntityRows$rows.forEach((row) => {
    result.rows.push(_objectSpread(_objectSpread({}, row), {}, { rowId: row.entity._id.toString() }));
  });

  result.page = pxEntityRows.page;
  result.totalRows = pxEntityRows.totalRows;
  result.extractor = extractors.find((ext) => ext._id === extractorId);

  return result;
};

const getPXProperties = (
entity,
textProperty,
paragraphNumberProperty) =>
{var _entity$template, _entity$metadata, _entity$metadata$text, _entity$metadata$text2, _entity$metadata2, _entity$metadata2$par, _entity$_id;
  const extractedParagraph = {
    sharedId: entity.sharedId,
    title: entity.title,
    language: entity.language,
    template: (_entity$template = entity.template) === null || _entity$template === void 0 ? void 0 : _entity$template.toString(),
    rowId: entity._id.toString(),
    paragraphText: ((_entity$metadata = entity.metadata) === null || _entity$metadata === void 0 ? void 0 : (_entity$metadata$text = _entity$metadata[textProperty]) === null || _entity$metadata$text === void 0 ? void 0 : (_entity$metadata$text2 = _entity$metadata$text[0].value) === null || _entity$metadata$text2 === void 0 ? void 0 : _entity$metadata$text2.toString()) || '',
    paragraphNumber: Number((_entity$metadata2 = entity.metadata) === null || _entity$metadata2 === void 0 ? void 0 : (_entity$metadata2$par = _entity$metadata2[paragraphNumberProperty]) === null || _entity$metadata2$par === void 0 ? void 0 : _entity$metadata2$par[0].value) || 0,
    _id: ((_entity$_id = entity._id) === null || _entity$_id === void 0 ? void 0 : _entity$_id.toString()) || ''
  };
  return extractedParagraph;
};

const PXParagraphLoader =
(headers) =>
// eslint-disable-next-line max-statements
async ({
  request,
  params: { sharedId = '', extractorId = '' }
}) => {var _await$settingsApi$ge, _paragraphs$rows;
  const urlSearchParams = new URLSearchParams(request.url.split('?')[1]);
  const { page = '1' } = searchParamsFromSearchParams(urlSearchParams);

  const result = {
    rows: [],
    page: { number: page, size: PAGE_SIZE },
    totalRows: 0
  };

  const defaultLanguage = (_await$settingsApi$ge = (await settingsApi.get(headers)).languages) === null || _await$settingsApi$ge === void 0 ? void 0 : _await$settingsApi$ge.find((lang) => lang.default);

  const extractors = await extractorsAPI.get(headers);
  const extractor = extractors.find((ext) => ext._id === extractorId);

  if (!extractorId || !sharedId || !extractor) return result;

  const query = {
    id: sharedId,
    extractorId,
    page: { number: Number(page), size: PAGE_SIZE }
  };

  const [paragraphs, [sourceEntity], templates] = await Promise.all([
  pxParagraphApi.getByParagraphExtractorId(query, headers),
  entitiesApi.getBySharedId({ sharedId, language: (defaultLanguage === null || defaultLanguage === void 0 ? void 0 : defaultLanguage.key) || '' }, headers),
  templatesApi.get(headers)]
  );

  const template = templates.find((temp) => temp._id === extractor.targetTemplateId);
  const textProperty = template === null || template === void 0 ? void 0 : template.properties.find(
    (property) => property._id === extractor.paragraphPropertyId
  );
  const numberProperty = template === null || template === void 0 ? void 0 : template.properties.find(
    (property) => property._id === extractor.paragraphNumberPropertyId
  );

  const paragraphsRows = (_paragraphs$rows = paragraphs.rows) === null || _paragraphs$rows === void 0 ? void 0 : _paragraphs$rows.map((row) => {
    const [defaultLanguageEntity, ...otherLanguagesEntities] = row.entities.map((entity) =>
    getPXProperties(entity, (textProperty === null || textProperty === void 0 ? void 0 : textProperty.name) || '', (numberProperty === null || numberProperty === void 0 ? void 0 : numberProperty.name) || '')
    );

    return _objectSpread(_objectSpread({}, defaultLanguageEntity), {}, { subRows: otherLanguagesEntities });
  });

  return {
    rows: paragraphsRows,
    page: paragraphs.page,
    totalRows: paragraphs.totalRows,
    extractor,
    sourceEntity
  };
};

export { ParagraphExtractorLoader, PXEntityLoader, PXParagraphLoader };