import api from "../../../utils/api";



import { RequestParams } from "../../../utils/RequestParams";
import * as formatter from "./formatter";





const getById = async ({
  _id,
  language,
  omitRelationships = true



}) => {
  try {
    const requestParams = new RequestParams({
      _id,
      omitRelationships
    });

    api.locale(language);

    const {
      json: { rows: response }
    } = await api.get('entities', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const getBySharedId = async (
{
  sharedId,
  language,
  omitRelationships = true



},
headers) =>
{
  try {
    const requestParams = new RequestParams(
      {
        sharedId,
        omitRelationships
      },
      headers
    );

    api.locale(language);

    const {
      json: { rows: response }
    } = await api.get('entities', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const save = async (entity) => {
  try {
    const requestParams = new RequestParams(entity);
    const { json: response } = await api.post('entities', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const coerceValue = async (
value,
type,
locale) =>
{
  try {
    const requestParams = new RequestParams({
      locale,
      value,
      type
    });
    const { json: response } = await api.post('entities/coerce_value', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

export { getById, save, coerceValue, formatter, getBySharedId };